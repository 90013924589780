import React, { useState, useEffect } from 'react';
import { Charts } from '../../MatchDetail/Charts';

export const Scorecard = ({
  scorecard,
  maxovers = [0, 0],
  striker = '',
  bowling = '',
  teams,
  probability,
  scoregrid,
  overs,
  complete,
  battingovers,
}) => {
  function isEven(n) {
    return n % 2 == 0;
  }
  const [curIns, setCurIns] = useState('inns' + scorecard.inns_now);
  let od,
    ev = '';
  if (isEven(scorecard.inns_now)) {
    ev = scorecard.bat_now;
    od = scorecard.bowl_now;
  } else {
    od = scorecard.bat_now;
    ev = scorecard.bowl_now;
  }
  let selections = [];
  for (let i = 1; i <= 4; i++) {
    if (scorecard['inns' + i]) {
      if (isEven(i)) {
        selections[i] = ev;
      } else {
        selections[i] = od;
      }
    }
  }
  let TotalW = scorecard[curIns]?.wkts ?? 0;
  let TotalR = scorecard[curIns]?.runs ?? 0;
  const handleInsChange = (event) => {
    setCurIns(event.target.value);
  };
  useEffect(() => {
    const newCurIns = `inns${scorecard.inns_now ?? 1}`;
    setCurIns(newCurIns);
  }, [scorecard.inns_now]);
  return (
    <div className='flex md:gap-[20px]'>
      <div className='flex-grow'>
        {scorecard[curIns] ? (
          <div className='anOver w-[100%] mb-[10px] bgwhite'>
            <div className='mb-[20px] grid grid-cols-6'>
              <div className='font-anton text-[#fff] text-[30px] md:text-[40px] uppercase col-span-3'>
                SCORECARD
              </div>
              <div className='font-chakra text-[#18062f] !text-right text-[12px] lg:text-[14px] col-start-4 col-span-3'>
                <div className='select-wrapper'>
                  <select
                    className='dropdown'
                    onChange={handleInsChange}
                    value={curIns}
                  >
                    {selections.map((sel, idx) => {
                      if (sel) {
                        return (
                          <option key={sel + idx} value={`inns${idx}`}>
                            {sel}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className='h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[12px] lg:text-[14px] grid grid-cols-12 border-solid border-[#18062f] border-[1px]'>
                <div className='pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px] col-span-7'>
                  BATTING
                </div>
                <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                  R
                </div>
                <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                  B
                </div>
                <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                  4s
                </div>
                <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                  6s
                </div>
                <div className='pt-[7px] text-center'>SR</div>
              </div>
              {scorecard[curIns].batting.map((bat, idx) => {
                if (bat.name !== '') {
                  return (
                    <div
                      key={'bat' + bat.name + idx}
                      className='grid grid-cols-12 font-chakra bg-white text-[10px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'
                    >
                      <div
                        className={`truncate col-span-4 py-[10px] pl-[10px] text-balance !text-left bold ${
                          striker === bat.name
                            ? 'border-l-[3px] border-solid border-[#f73261]'
                            : null
                        }`}
                      >
                        {bat.name}
                      </div>
                      <div className='py-[10px] col-start-5 col-span-3 !text-left pl-[5px] border-solid border-[#c5c2c8] border-r-[1px]'>
                        {bat.status}
                      </div>
                      <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                        {bat.runs}
                      </div>
                      <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                        {bat.balls}
                      </div>
                      <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                        {bat.fours}
                      </div>
                      <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                        {bat.sixes}
                      </div>
                      <div className='py-[10px] text-center'>
                        {bat.balls !== '0' ? (
                          <>{Math.round((bat.runs / bat.balls) * 100)}</>
                        ) : null}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              <div className='grid grid-cols-12 font-chakra bg-white text-[10px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                <div className='col-span-7 py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px]'>
                  Extras{' '}
                  <span className='font-normal'>
                    ({scorecard[curIns].extras.nb} nb,&nbsp;
                    {scorecard[curIns].extras.wd} w,&nbsp;
                    {scorecard[curIns].extras.lb} lb,&nbsp;
                    {scorecard[curIns].extras.b} b)
                  </span>
                </div>
                <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                  {scorecard[curIns].extras.nb +
                    scorecard[curIns].extras.wd +
                    scorecard[curIns].extras.lb +
                    scorecard[curIns].extras.b}
                </div>
                <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'></div>
                <div className='py-[10px] text-center'></div>
              </div>
              <div className='grid grid-cols-12 font-chakra bg-white text-[12px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                <div className='col-span-7 py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] text-[18px]'>
                  TOTAL
                </div>
                <div className='py-[10px] pl-[10px] !text-left bold col-start-8 col-span-2'>
                  {TotalR}/{TotalW}
                </div>
                <div className='py-[10px] text-center col-start-10 col-span-3'>
                  {/* {maxovers[parseInt(scorecard.inns_now)-1]}  */}
                  {battingovers[parseInt(curIns.substring(4)) - 1]} OVERS
                </div>
              </div>
            </div>

            <div className='m-[10px]'>still to bat</div>
            <div className='m-[10px]'>fall of wickets</div>

            <div className='grid grid-cols-12 h-[28px] bg-[#d2ff00] font-chakra bold text-[#18062f] text-[14px] flex border-solid border-[#18062f] border-[1px]'>
              <div className=' col-span-7 pt-[7px] pl-[10px] border-solid border-[#18062f] border-r-[1px]'>
                BOWLING
              </div>
              <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                W
              </div>
              <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                R
              </div>
              <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                OV
              </div>
              <div className='pt-[7px] text-center border-solid border-[#18062f] border-r-[1px]'>
                NB
              </div>
              <div className='pt-[7px] text-center'>WD</div>
            </div>
            {scorecard[curIns].bowling.map((bowl, idx) =>
              bowl.name !== '' ? (
                <div key={'bowl' + bowl.name + idx} className='grid grid-cols-12 font-chakra bg-white text-[10px] lg:text-[14px] text-[#18062f] border-solid border-[#18062f] border-[1px] border-t-0'>
                  <div
                    className={`py-[10px] pl-[10px] !text-left bold border-solid border-[#c5c2c8] border-r-[1px] border-r-[#c5c2c8] col-span-7 ${
                      bowling === bowl.name
                        ? 'border-l-[3px] border-solid border-[#f73261]'
                        : null
                    }`}
                  >
                    {bowl.name}
                  </div>
                  <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px] bold'>
                    {bowl.wickets}
                  </div>
                  <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                    {bowl.runs}
                  </div>
                  <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                    {bowl.overs}
                  </div>
                  <div className='py-[10px] text-center border-solid border-[#c5c2c8] border-r-[1px]'>
                    {bowl.nb}
                  </div>
                  <div className='py-[10px] text-center'>{bowl.wd}</div>
                </div>
              ) : null
            )}
          </div>
        ) : null}
      </div>
      <div className='hidden md:inline'>
        <Charts
          teams={teams}
          probability={probability}
          scoregrid={scoregrid}
          maxovers={maxovers}
          title={false}
          complete={complete}
        />
      </div>
    </div>
  );
};
export default Scorecard;
